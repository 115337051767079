import React from 'react';

const ScrollUpSvg = () => (
    <svg
        height={25}
        width={25}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="130 150 250 250"
        xmlSpace="preserve"
    >
        <path
            style={{
                fill: "#fff",
            }}
            d="M253.2 219.2 156 316.4c-3.6 3.6-9.6 3.6-13.2 0l-9.2-9.2c-3.6-3.6-3.6-9.6 0-13.2l113.2-113.2c2-2 4.4-2.8 6.8-2.8s4.8.8 6.8 2.8L373.6 294c3.6 3.6 3.6 9.6 0 13.2l-9.2 9.2c-3.6 3.6-9.6 3.6-13.2 0z"
        />
    </svg>
);

export default ScrollUpSvg;
