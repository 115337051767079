import React from 'react'
import Content from "./content";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";
import {Helmet} from "react-helmet";

const Top = () => {
    const {
        topPosts,
        setTopPosts,
        topPage,
        setTopPage,
        showSignUp,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost,
        categories
    } = useOutletContext();

    React.useEffect(() => {
        if (topPosts == null) {
            fetchData(1);
        }
    }, [topPosts]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?type=top&page=${page}`;

        axios.get(url).then((response) => {
            if (topPosts == null || page === 1) {
                setTopPosts(response.data.data.posts);
            } else {
                setTopPosts(topPosts.concat(response.data.data.posts));
            }
            setTopPage(response.data.data.page.nextPage);
        });
    }

    const deleteCallback = () => {
        setTopPosts(null);
        fetchData(1);
    }

    return (
        <>
            <Helmet>
                <title>Top posts of the week</title>
                <meta name="description" content="Top posts of the week"/>
                <meta property="og:title" content="Top posts of the week"/>
                <meta property="og:description" content="Top posts of the week"/>
            </Helmet>
            <Content posts={topPosts} nextPage={() => fetchData(topPage)} pageNumber={topPage}
                     showSignUp={showSignUp} showReportPost={showReportPost} deleteCallback={deleteCallback}
                     preferredLanguage={preferredLanguage} userProfile={userProfile} showDeletePost={showDeletePost}
                     showLanguageSelection={showLanguageSelection} showEditPost={showEditPost} categories={categories}/>
        </>
    )
}

export default Top