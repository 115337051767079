import React from 'react'
import {Button, Col, Container, Image, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

// img
import error500 from '../../assets/images/error/500.png'

const Error = () => {
    let history = useNavigate()
    return (
        <>
            <Container className="p-0">
                <Row className="no-gutters height-self-center">
                    <Col sm="12" className="text-center align-self-center">
                        <div className="iq-error position-relative mt-5">
                            <Image src={error500} className="img-fluid iq-error-img" alt=""/>
                            <h2 className="mb-0 text-center">Oops! Something went wrong.</h2>
                            <Button variant="primary" className="mt-3" onClick={() => history('/')}><i
                                className="ri-home-4-line me-1"></i>Back to Home</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Error;
