import React, {createRef, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Form, Spinner} from "react-bootstrap";
import {USER_URL} from "../../utilities/urls";
import axios from "axios";

const VerifyEmail = ({emailAddress, signUp, showPreferences}) => {
    const [inputRefsArray] = useState(() =>
        Array.from({length: 6}, () => createRef())
    );

    const [currentIndex, setCurrentIndex] = useState(0);
    const [letters, setLetters] = useState(() =>
        Array.from({length: 6}, () => "")
    );

    const [showSpinner, setShowSpinner] = useState(false);
    const [showResendSpinner, setShowResendSpinner] = useState(false);

    const handleKeyPress = (e) => {
        const value = e.key;
        const index = Number(e.target.name[0]);
        if (/\b\d\b/gm.test(value)) {
            setLetters((letters) =>
                letters.map((letter, letterIndex) =>
                    letterIndex === index ? value : letter
                )
            );
        } else if (value === "") {
            setLetters((letters) =>
                letters.map((letter, letterIndex) =>
                    letterIndex === index ? "" : letter
                )
            );
        }

        if (e?.target?.name?.includes("code")) {
            setCurrentIndex((prevIndex) => {
                if (!/\b\d\b/gm.test(e.key)) {
                    return prevIndex;
                }

                if (prevIndex < 5) {
                    const nextIndex = prevIndex + 1;
                    const nextInput = inputRefsArray?.[nextIndex]?.current;
                    nextInput.focus();
                    nextInput.select();

                    return nextIndex;
                } else {
                    return prevIndex;
                }
            });
        }
    };

    useEffect(() => {
        // add the event listener for keyup keyboard event
        window.addEventListener("keyup", handleKeyPress, false);

        // remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const onEmailVerificationFormSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();

        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/verify',
            data: {
                emailAddress: emailAddress,
                verificationCode: letters.reduce((accumulator, currentValue) => accumulator + currentValue, "")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            window.removeEventListener("keyup", handleKeyPress);
            showPreferences();
        }).catch(function (error) {
            setShowSpinner(false);
            window.removeEventListener("keyup", handleKeyPress);
            console.error(error);
        });
    }

    const resendCode = () => {
        setShowResendSpinner(true);
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/verify/email',
            data: {
                emailAddress: emailAddress
            }
        };

        axios.request(options).then(function (response) {
            setShowResendSpinner(false);
        }).catch(function (error) {
            setShowResendSpinner(false);
            console.error(error);
        });
    }

    return (
        <div className="sign-in-from">
            <Link to="#" onClick={() => signUp()}>&lt; Back</Link>
            <Form onSubmit={onEmailVerificationFormSubmit} className="mt-4">
                <Form.Group className="form-group mt-5">
                    <Form.Control type="email" className="mb-0" name="emailAddress" required={true}
                                  disabled
                                  value={emailAddress}/>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Please enter the 6-digit verification code we sent to the above email.
                        This code expires in 15 minutes.</Form.Label>
                    <div className="d-flex justify-content-center">
                        {inputRefsArray.map((ref, index) => {
                            return (
                                <Form.Control ref={ref}
                                              key={index}
                                              type="text"
                                              pattern="[0-9]"
                                              inputMode="numeric"
                                              className="mb-0 email-code"
                                              name={`${index}code`}
                                              autoFocus={index === 0}
                                              required={true}
                                              onClick={(e) => {
                                                  setCurrentIndex(index);
                                                  e.target.select();
                                              }}
                                              value={letters[index]}/>
                            );
                        })}
                    </div>
                </Form.Group>
                <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                    {
                        showSpinner ?
                            <>
                                <div></div>
                                <Spinner className="me-4 spinner-margin"/>
                            </> :
                            showResendSpinner ?
                                <Spinner className="ms-4 spinner-margin"/> :
                                <>
                                    <Link to="#" onClick={resendCode} style={{color: "var(--bs-primary)"}}>
                                        Resend Code?</Link>
                                    <Button variant="primary" type="submit">Submit</Button>
                                </>
                    }
                </div>
            </Form>
        </div>
    );
};

export default VerifyEmail;