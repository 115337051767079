import React, {useState} from 'react';
import {Button, FloatingLabel, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {SPACES_URL} from "../../utilities/urls";

const CreateSpace = ({show, handleClose, accessToken}) => {
    const [validated, setValidated] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const onFormSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        const formData = new FormData(e.target);

        let isValid = true;
        if (!formData.get("name").trim()) {
            isValid = false;
        }

        setValidated(true);

        if (!isValid) {
            return
        }

        setShowSpinner(true);

        const options = {
            method: 'POST',
            url: SPACES_URL + '/space',
            headers: {
                'Authorization': accessToken
            },
            data: {
                name: formData.get("name").trim()
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Create New Space</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={onFormSubmit} className="mx-3">
                    <Form.Group className="mb-3" controlId="name">
                        <FloatingLabel controlId="name" label="Name of the space">
                            <Form.Control
                                name="name"
                                as="input"
                                placeholder="Enter name of the space"
                                required={true}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            Please enter a name for the space.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {
                            showSpinner ? <Spinner animation="border" className="create-spinner"/> :
                                <Button variant="primary" type="submit" name="create">
                                    Create
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default CreateSpace;