import React from 'react'
import {useOutletContext, useParams} from 'react-router-dom'
import Content from "./content";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";
import {Helmet} from "react-helmet";

const Category = () => {
    const params = useParams();
    const category = params.id;

    const {
        categoryPosts,
        setCategoryPosts,
        categoryPage,
        setCategoryPage,
        showSignUp,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost,
        categories
    } = useOutletContext();

    React.useEffect(() => {
        if (!categoryPosts[category]) {
            fetchData(1);
        }
    }, [category, categoryPosts[category]]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?category=${category}&page=${page}`

        axios.get(url).then((response) => {
            const currentCategoryPosts = categoryPosts[category];

            if (!currentCategoryPosts || page === 1) {
                const newCategoryPosts = {...categoryPosts, [category]: response.data.data.posts}
                setCategoryPosts(newCategoryPosts);

                setCategoryPage({...categoryPage, [category]: response.data.data.nextPage});
            } else {
                categoryPosts[category] = currentCategoryPosts.concat(response.data.data.posts)
                setCategoryPosts(categoryPosts);

                categoryPage[category] = response.data.data.nextPage
                setCategoryPage(categoryPage);
            }
        });
    }

    const categoryName = categories[category];

    const deleteCallback = () => {
        const newCategoryPosts = {...categoryPosts, [category]: null}
        setCategoryPosts(newCategoryPosts);
        fetchData(1);
    }

    return categoryPosts[category] && (
        <>
            <Helmet>
                <title>{categoryName}</title>
                <meta name="description" content={`${categoryName}`}/>
                <meta property="og:title" content={`${categoryName}`}/>
                <meta property="og:description" content={`${categoryName}`}/>
            </Helmet>
            <Content posts={categoryPosts[category]} nextPage={() => fetchData(categoryPage[category])}
                     pageNumber={categoryPage[category]} showSignUp={showSignUp}
                     showReportPost={showReportPost} preferredLanguage={preferredLanguage} userProfile={userProfile}
                     deleteCallback={deleteCallback} showLanguageSelection={{showLanguageSelection}}
                     showDeletePost={showDeletePost} showEditPost={showEditPost} categories={categories}/>
        </>
    )
}

export default Category