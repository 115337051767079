import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import Preferences from "../auth/preferences";
import SignIn from "../auth/sign-in";
import ForgotPassword from "../auth/forgot-password";
import Selection from "../auth/selection";
import VerifyEmail from "../auth/verify-email";
import SignUp from "../auth/sign-up";

const AuthModal = ({show, handleClose, authCallBack}) => {
    const [isSignUpPage, setSignUpPage] = useState(false);
    const [isEmailVerificationPage, setEmailVerificationPage] = useState(false);
    const [isPreferencesPage, setPreferencesPage] = useState(false);
    const [isSignInPage, setSignInPage] = useState(true);
    const [isPasswordResetPage, setIsPasswordResetPage] = useState(false);

    const [header, setHeader] = useState('Sign in');
    const [emailAddress, setEmailAddress] = useState('');

    const signIn = () => {
        setHeader("Sign in");
        setSignUpPage(false);
        setEmailVerificationPage(false);
        setPreferencesPage(false);
        setSignInPage(true);
        setIsPasswordResetPage(false);
    }

    const verifyEmail = (emailAddress) => {
        setEmailAddress(emailAddress);
        setHeader("Verify Email");
        setSignUpPage(false);
        setEmailVerificationPage(true);
        setPreferencesPage(false);
        setSignInPage(false);
        setIsPasswordResetPage(false);
    }

    const showPreferences = () => {
        setHeader("Preferences");
        setSignUpPage(false);
        setEmailVerificationPage(false);
        setPreferencesPage(true);
        setSignInPage(false);
        setIsPasswordResetPage(false);
    }

    const signUp = () => {
        setHeader("Sign up");
        setSignUpPage(true);
        setEmailVerificationPage(false);
        setPreferencesPage(false);
        setSignInPage(false);
        setIsPasswordResetPage(false);
    }

    const forgotPassword = () => {
        setHeader("Forgot Password");
        setSignUpPage(false);
        setEmailVerificationPage(false);
        setPreferencesPage(false);
        setSignInPage(false);
        setIsPasswordResetPage(true);
    }

    return (
        <Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isSignUpPage ? <SignUp signIn={signIn} verifyEmail={verifyEmail}/>
                        : isPreferencesPage ?
                            <Preferences handleClose={handleClose} authCallBack={authCallBack}/>
                            : isSignInPage ?
                                <SignIn signUp={signUp} verifyEmail={verifyEmail} forgotPassword={forgotPassword}
                                        handleClose={handleClose} authCallBack={authCallBack}/>
                                : isPasswordResetPage ?
                                    <ForgotPassword signIn={signIn}/>
                                    : isEmailVerificationPage ?
                                        <VerifyEmail emailAddress={emailAddress} signUp={signUp} showPreferences={showPreferences}
                                                     authCallBack={authCallBack}/>
                                        : <Selection signIn={signIn} handleClose={handleClose}/>

                }
            </Modal.Body>
        </Modal>
    )
}

export default AuthModal;