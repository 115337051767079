import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {Provider} from 'react-redux';
import {persistor, store} from './store'
import {IndexRouters} from './router';
import {PersistGate} from "redux-persist/integration/react";
import 'react-toastify/dist/ReactToastify.css'
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {setAccessToken, setUserProfile} from "./store/app/reducers";
import {USER_URL} from "./utilities/urls";

axios.interceptors.request.use(function (config) {
    config.withCredentials = true;
    return config
}, function (error) {
    return Promise.reject(error);
});

const handleSignOut = () => {
    store.dispatch(setUserProfile(null));
    store.dispatch(setAccessToken(null));

    const options = {
        method: 'POST',
        url: USER_URL + '/v1/account/signout',
    };

    axios.request(options).then(function (response) {

    }).catch(function (error) {
        console.error(error);
    });
};

axios.interceptors.response.use(function (response) {
        return response;
    },
    function (error) {
        if ((error?.response?.status === 401 && error.config.url.includes('/v1/account/signin')) ||
            (error?.response?.status !== 200 && !(error.config.url.includes('/v1/user') || error.config.url.includes('/v1/social')))) {
            toast.error(error?.response?.data?.message, {
                position: "bottom-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
        }

        if (error?.response?.status === 401) {
            handleSignOut();
        }

        return Promise.reject(error)
    }
);

const router = createBrowserRouter(IndexRouters, {basename: process.env.PUBLIC_URL})

ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App>
                <RouterProvider router={router}/>
                <ToastContainer
                    className="p-3"
                    position="bottom-right"
                    style={{zIndex: 99999999}}
                    autoClose={5000}
                    closeOnClick
                />
            </App>
        </PersistGate>
    </Provider>
)
