import React, {useState} from 'react'
import {Offcanvas} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {faLink, faShare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";

const Share = ({showIcon = true, imageRef, title, url}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShare = async () => {
        if (window.navigator.share) {
            // const newFile = await toBlob(imageRef.current);
            const data = {
                // files: [
                //   new File([newFile], 'image.png', {
                //     type: newFile.type,
                //   }),
                // ],
                // text: title,
                title: title,
                url: url
            };

            try {
                await navigator.share(data);
            } catch (err) {
                console.warn(err);
            }
        } else {
            setShow(true);
        }
    }

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    return (
        <>
            <Link to="#" onClick={() => handleShare()} id="share-button"
                  className="d-flex align-items-center feather-icon w-100">
                {
                    showIcon ? <FontAwesomeIcon icon={faShare} style={{fontSize: 1.3 + "rem"}}/> :
                        <div className="data ms-2">
                            <p className="mb-0">Share</p>
                        </div>
                }
            </Link>
            <Offcanvas show={show} onHide={handleClose} placement='bottom'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Share</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-wrap align-items-center">
                        <FacebookShareButton url={url} className="share-button">
                            <FacebookIcon size={32} round/>
                        </FacebookShareButton>
                        <LinkedinShareButton title={title} url={url} className="share-button">
                            <LinkedinIcon size={32} round/>
                        </LinkedinShareButton>
                        <PinterestShareButton description={title} media={url} url={url} className="share-button">
                            <PinterestIcon size={32} round/>
                        </PinterestShareButton>
                        <RedditShareButton title={title} url={url} className="share-button">
                            <RedditIcon size={32} round/>
                        </RedditShareButton>
                        <TelegramShareButton title={title} url={url} className="share-button">
                            <TelegramIcon size={32} round/>
                        </TelegramShareButton>
                        <TwitterShareButton title={title} url={url} className="share-button">
                            <XIcon size={32} round/>
                        </TwitterShareButton>
                        <WhatsappShareButton title={title} url={url} className="share-button">
                            <WhatsappIcon size={32} round/>
                        </WhatsappShareButton>
                        <Link className="share-button" to="#" onClick={() => copyTextToClipboard(url)}>
                            <FontAwesomeIcon icon={faLink} style={{fontSize: 1.5 + "rem"}}/>
                        </Link>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default Share;

