import React from 'react';
import {Button} from "react-bootstrap";
import GoogleButton from "../common/google-button";
import FacebookButton from "../common/facebook-button";

const Selection = ({signIn, handleClose}) => {
    return (
        <div>
            <p>By continuing, you agree to PostUp's <a href="/terms" target="_blank">Terms and Conditions</a> and acknowledge that
                you've read our <a href="/privacy-policy" target="_blank">Privacy Policy</a></p>

            {/*<GoogleButton handleClose={handleClose}/>*/}
            {/*<FacebookButton handleClose={handleClose}/>*/}
            <div style={{width: "247px", height: "40px", margin: "1rem auto"}}>
                <Button className="w-100" onClick={signIn}>Use email</Button>
            </div>
        </div>
    );
};

Selection.propTypes = {};

export default Selection;