import React from 'react';
import {Nav} from "react-bootstrap";
import {Link} from 'react-router-dom';

const UserProfileNavLinks = ({location}) => {
    return (<Nav as="ul" variant="pills"
                 className="align-items-center justify-content-center flex-row nav-items p-0 m-0 mb-2 position-relative">
        <Nav.Item as="li" className="col-4 p-0 ">
            <Link to="/user" className={`${location.pathname === '/user' ? 'active' : ''} nav-link`}>My Posts</Link>
        </Nav.Item>
        <Nav.Item as="li" className="col-4 p-0">
            <Link to="/user/likes" className={`${location.pathname === '/user/likes' ? 'active' : ''} nav-link`}>Likes</Link>
        </Nav.Item>
        <Nav.Item as="li" className=" col-4 p-0">
            <Link to="/user/following"
                  className={`${location.pathname === '/user/following' ? 'active' : ''} nav-link`}>Following</Link>
        </Nav.Item>
    </Nav>);
}


export default UserProfileNavLinks;