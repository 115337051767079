import React, {useRef, useState} from 'react';
import {Button, FloatingLabel, Form, Modal, ProgressBar, Spinner} from "react-bootstrap";
import axios from "axios";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";
import {Link, useNavigate} from "react-router-dom";
import {MEDIA_URL, POST_URL} from "../../utilities/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faSquarePollVertical, faVideo} from "@fortawesome/free-solid-svg-icons";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import TagsInput from "react-tagsinput";
import PostPlaceholder from "./post-placeholder";

const EditPost = ({show, handleClose, postId}) => {
    const navigate = useNavigate();

    const categoryRef = useRef();

    const categories = useSelector(state => state.categories)

    const [showProgressbar, setShowProgressbar] = useState(false);
    const [postType, setPostType] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [validated, setValidated] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [numberOfOptions, setNumberOfOptions] = useState([]);
    const [showPoll, setShowPoll] = useState(false);
    const [tags, setTags] = useState([]);

    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        if (postId) {
            fetchData();
        }
    }, [postId]);

    React.useEffect(() => {
        if (post) {
            setPostType(post.type);
            setShowImage(post.media?.url);
            setTags(post.tags);
            post.pollOptions && setNumberOfOptions(post.pollOptions?.length);
        }
    }, [post]);

    const fetchData = (page) => {
        setLoading(true);
        const url = POST_URL + `/v1/post/${postId}`;

        axios.get(url).then((response) => {
            setLoading(false);
            setPost(response.data.data);
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        const filename = file.name
        const contentType = file.type
        const fileType = contentType.startsWith("image") ? 'image' : 'video'
        setPostType(fileType);

        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/media/upload',
            headers: {
                'Content-Type': contentType,
                'File-Name': filename,
                'File-Type': fileType
            },
            data: file,
            onUploadProgress: progressEvent => {
                const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(uploadProgress);
            }
        };

        setShowProgressbar(true);
        axios.request(options).then(function (response) {
            setUploadResponse(response.data.data);
            setShowImage(true);
        }).catch(function (error) {
            console.error(error);
        });
    };

    const onFormSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        const formData = new FormData(e.target);

        let isValid = true;
        if (!formData.get("content").trim()) {
            isValid = false;
        } else if (!formData.get("category")) {
            isValid = false;
        }

        const pollOptions = [];
        for (let i = 0; i < numberOfOptions.length; i++) {
            const optionNumber = numberOfOptions[i];
            const option = formData.get(`${optionNumber}option`).trim();
            if (!option && i !== numberOfOptions.length - 1) {
                isValid = false;
                break;
            } else {
                if (option) {
                    pollOptions.push(option);
                }
            }
        }

        setValidated(true);

        if (!isValid) {
            return
        }

        setShowSpinner(true);

        const options = {
            method: 'PATCH',
            url: POST_URL + `/v1/post`,
            data: {
                postId: postId,
                mediaId: uploadResponse ? uploadResponse.mediaId : post.mediaId,
                pollOptions: showPoll ? pollOptions : post.pollOptions,
                content: formData.get("content").trim(),
                category: formData.get("category"),
                tags: tags.map(tag => tag.trim()).filter(tag => tag !== "")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();
            navigate(`/post/${response.data.data.postId}?source=edit`);
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    const removeImage = () => {
        setUploadResponse(null);
        setShowImage(false);
        post.mediaId = null;
    };

    const handlePoll = () => {
        if (numberOfOptions.length === 0) {
            setNumberOfOptions([0]);
        }
        setShowPoll(true);
    };

    const createOption = (e) => {
        const index = Number(e.target.name[0]);
        if (index === numberOfOptions.length - 1) {
            setNumberOfOptions(numberOfOptions.concat(index + 1));
        }
    };

    const deleteOption = (optionNumber) => {
        const index = numberOfOptions.indexOf(optionNumber);
        numberOfOptions.splice(index, 1);

        if (numberOfOptions.length === 0) {
            setNumberOfOptions([0]);
        }
    };

    return (
        <Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Edit Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (loading || !post) ?
                        <PostPlaceholder/> :
                        <Form noValidate validated={validated} onSubmit={onFormSubmit} className="mx-3">
                            <Form.Group className="mb-3" controlId="category">
                                <Form.Select name="category" required={true} ref={categoryRef}>
                                    <option disabled value="">Select a Category</option>
                                    {categories.map(category => (
                                        <option key={category.code} value={category.code}
                                                selected={post.category === category.code}>{category.name}</option>))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select a category.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="content">
                                <FloatingLabel controlId="content" label="What's on your mind?">
                                    <Form.Control
                                        name="content"
                                        as="textarea"
                                        placeholder="What's on your mind?"
                                        style={{height: '100px'}}
                                        required={true}
                                        defaultValue={post.content}
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    Please enter content.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tags">
                                <TagsInput value={tags} onChange={(tags) => setTags(tags)} placeholder="# Tags"/>
                            </Form.Group>

                            <Form.Group className="mb-3 mt-5 d-flex flex-row" controlId="input">
                                <div>
                                    <input hidden id="image-input" required={true} type="file" name="image-file"
                                           accept="image/*"
                                           onInputCapture={(event) => handleFileUpload(event)}/>
                                    <label htmlFor="image-input" className="pointer d-flex create-options">
                                        <FontAwesomeIcon icon={faImage} className="create-icon"/>
                                        <div className="create-p">Image</div>
                                    </label>
                                </div>

                                <div className="ms-5">
                                    <input hidden id="video-input" required={true} type="file" name="video-file"
                                           accept="video/*"
                                           onInputCapture={(event) => handleFileUpload(event)}/>
                                    <label htmlFor="video-input" className="pointer d-flex create-options">
                                        <FontAwesomeIcon icon={faVideo} className="create-icon"/>
                                        <div className="create-p">Video</div>
                                    </label>
                                </div>

                                <div className="ms-5">
                                    <label className="pointer d-flex create-options" onClick={handlePoll}>
                                        <FontAwesomeIcon icon={faSquarePollVertical} className="create-icon"/>
                                        <div className="create-p">Poll</div>
                                    </label>
                                </div>
                            </Form.Group>
                            <div className="mb-3">
                                {
                                    showImage ?
                                        <>
                                            <button type="button" className="btn-close float-end" aria-label="Close"
                                                    onClick={removeImage}/>
                                            {
                                                (postType === "image" ?
                                                    <img src={uploadResponse ? uploadResponse.url : post.media.url}
                                                         className="image-upload"/> :
                                                    <ReactPlayer
                                                        url={uploadResponse ? uploadResponse.url : post.media.url}
                                                        controls
                                                        muted={true} width="100%"
                                                        height="300px"/>)
                                            }
                                        </>
                                        :
                                        showProgressbar &&
                                        <ProgressBar animated striped now={uploadProgress}></ProgressBar>
                                }
                            </div>
                            {
                                showPoll &&
                                <>
                                    {
                                        numberOfOptions.map((optionNumber, index) => (
                                            <Form.Group className="mb-3 d-flex" controlId={`option-${optionNumber}`}
                                                        key={optionNumber}>
                                                <Form.Control name={`${optionNumber}option`} type="text"
                                                              placeholder="Option"
                                                              required={index !== numberOfOptions.length - 1}
                                                              onFocus={createOption} style={{paddingRight: 3 + "rem"}}/>
                                                <Link to="#" className="comment-send feather-icon me-2"
                                                      onClick={() => deleteOption(optionNumber)}>
                                                    <FontAwesomeIcon icon={faTrashCan}/>
                                                </Link>
                                            </Form.Group>
                                        ))
                                    }
                                </>
                            }
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                {
                                    showSpinner ? <Spinner animation="border"/> :
                                        <Button variant="primary" type="submit" name="update">
                                            Update
                                        </Button>
                                }
                            </Modal.Footer>
                        </Form>
                }
            </Modal.Body>

        </Modal>
    )
        ;
}

export default EditPost;