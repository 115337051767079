import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import InfiniteScroll from "react-infinite-scroll-component";
import PostPlaceholder from "../../components/postup/post-placeholder";
import Post from "../../components/postup/post";
import axios from "axios";
import {useOutletContext} from "react-router-dom";
import {POSTS_URL} from "../../utilities/urls";

const UserLikes = () => {
    const {
        showDeletePost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        categories
    } = useOutletContext();

    const [likedPosts, setLikedPosts] = useState([]);
    const [likedPostsNextPage, setLikedPostsNextPage] = useState(1);

    React.useEffect(() => {
        if (userProfile && likedPosts.length === 0) {
            fetchLikedPosts(1);
        }
    }, []);

    const fetchLikedPosts = (page) => {
        const url = POSTS_URL + `/v1/posts?liked=true&page=${page}`;

        axios.get(url).then((response) => {
            setLikedPosts(likedPosts.concat(response.data.data.posts));
            setLikedPostsNextPage(response.data.data.page.nextPage);
        });
    }

    const callback = () => {
        const url = POSTS_URL + `/v1/posts?liked=true&page=1&size=${likedPosts.length}`;

        axios.get(url).then((response) => {
            setLikedPosts(response.data.data.posts);
            setLikedPostsNextPage(response.data.data.page.nextPage);
        });
    }

    const showDeletePostWithCallback = (postId) => {
        showDeletePost(postId, callback);
    }

    return userProfile && (
        <Row>
            <Col lg={12} className="row m-0 p-0">
                <InfiniteScroll
                    dataLength={likedPosts.length}
                    next={() => fetchLikedPosts(likedPostsNextPage)}
                    hasMore={likedPostsNextPage != null}
                    loader={
                        <PostPlaceholder/>
                    }
                >
                    {
                        likedPosts.map((post, index) => {
                            return (<Col sm={12} key={index}>
                                <Post post={post}
                                      showDeletePost={showDeletePostWithCallback}
                                      preferredLanguage={preferredLanguage}
                                      userProfile={userProfile} categories={categories}
                                      showLanguageSelection={showLanguageSelection}/>
                            </Col>)
                        })
                    }
                </InfiniteScroll>
            </Col>
        </Row>
    )
}

export default UserLikes;