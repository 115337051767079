import React, {useRef, useState} from 'react';
import {Button, FloatingLabel, Form, Modal, ProgressBar, Spinner} from "react-bootstrap";
import axios from "axios";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";
import {useNavigate} from "react-router-dom";
import {MEDIA_URL, POST_URL} from "../../utilities/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import TagsInput from "react-tagsinput";

const CreateMeme = ({show, handleClose}) => {
    let navigate = useNavigate();

    const categoryRef = useRef();

    const categories = useSelector(state => state.categories)

    const [showProgressbar, setShowProgressbar] = useState(false);
    const [postType, setPostType] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showPreview, setShowPreview] = useState(false);
    const [base64Image, setBase64Image] = useState(null);
    const [validated, setValidated] = useState(false);
    const [previewValidated, setPreviewValidated] = useState(false);
    const [topText, setTopText] = useState('');
    const [bottomText, setBottomText] = useState('');
    const [imageTextRequired, setImageTextRequired] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showPreviewSpinner, setShowPreviewSpinner] = useState(false);
    const [isPreview, setIsPreview] = useState(true);
    const [isContinue, setIsContinue] = useState(false);
    const [tags, setTags] = useState([]);

    React.useEffect(() => {
        if (isContinue) {
            setIsContinue(false);
        }
    }, [topText, bottomText, base64Image]);

    React.useEffect(() => {
        if (base64Image) {
            setIsContinue(true);
        }
    }, [base64Image]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        const filename = file.name
        const contentType = file.type
        const fileType = contentType.startsWith("image") ? 'image' : 'video'
        setPostType(fileType);

        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/media/upload',
            headers: {
                'Content-Type': contentType,
                'File-Name': filename,
                'File-Type': fileType
            },
            data: file,
            onUploadProgress: progressEvent => {
                const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(uploadProgress);
            }
        };

        setShowProgressbar(true);
        axios.request(options).then(function (response) {
            setUploadResponse(response.data.data);
            setShowPreview(false);
            setShowProgressbar(false);
            setShowImage(true);
        }).catch(function (error) {
            setShowProgressbar(false);
            console.error(error);
        });
    };

    const onFormSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        const formData = new FormData(e.target);

        let isValid = true;
        if (!formData.get("content").trim()) {
            isValid = false;
        } else if (!formData.get("category")) {
            isValid = false;
        } else if (!topText && !bottomText) {
            isValid = false;
        }

        setValidated(true);

        if (!isValid) {
            return
        }

        setShowSpinner(true);

        let options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/meme',
            data: {
                mediaId: uploadResponse.mediaId,
                topText: topText.trim(),
                bottomText: bottomText.trim()
            }
        };

        axios.request(options).then(function (response) {
            options = {
                method: 'POST',
                url: POST_URL + '/v1/post',
                data: {
                    mediaId: response.data.mediaId,
                    content: formData.get("content").trim(),
                    category: formData.get("category"),
                    tags: tags.map(tag => tag.trim()).filter(tag => tag !== "")
                }
            };

            axios.request(options).then(function (response) {
                setShowSpinner(false);
                handleClose();
                navigate(`/post/${response.data.data.postId}?source=create`);
            }).catch(function (error) {
                setShowSpinner(false);
                console.error(error);
            });
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });

    }

    const handlePreview = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        let isValid = true;
        if ((!topText || topText.trim() === '') && (!bottomText || bottomText.trim() === '')) {
            isValid = false;
            setImageTextRequired(true);
        } else if (!uploadProgress) {
            isValid = false;
        }

        setPreviewValidated(true);

        if (!isValid) {
            return
        }

        setShowPreviewSpinner(true);

        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/meme/preview',
            data: {
                mediaId: uploadResponse.mediaId,
                topText: topText.trim(),
                bottomText: bottomText.trim()
            }
        };

        axios.request(options).then(function (response) {
            setBase64Image(response.data);
            setShowImage(false);
            setShowPreview(true);
            setShowPreviewSpinner(false);
        }).catch(function (error) {
            setShowPreviewSpinner(false);
            console.error(error);
        });
    }

    const handleContinue = (e) => {
        if (!isContinue) {
            handlePreview(e);
        } else {
            e.preventDefault();
            setIsPreview(false);
            setIsContinue(false);
        }
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Create Meme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                (isPreview || isContinue) ?
                    <Form noValidate validated={previewValidated} onSubmit={handleContinue} className="mx-3">
                        <Form.Group className="mb-3" controlId="topText">
                            <Form.Label>Top Text</Form.Label>
                            <Form.Control name="topText" type="text" required={imageTextRequired}
                                          placeholder="Text appearing at top of the image"
                                          value={topText} onChange={(e) => {
                                setTopText(e.target.value);
                                setImageTextRequired(false);
                            }}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="bottomText">
                            <Form.Label>Bottom Text</Form.Label>
                            <Form.Control name="bottomText" type="text" required={imageTextRequired}
                                          placeholder="Text appearing at bottom of the image"
                                          value={bottomText} onChange={(e) => {
                                setBottomText(e.target.value);
                                setImageTextRequired(false);
                            }}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter text appearing on the image.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="file">
                            <div>
                                <input hidden id="meme-input" required={true} type="file" name="image-file"
                                       className="form-control rounded align-text-bottom mb-2"
                                       accept="image/*"
                                       onInputCapture={(event) => handleFileUpload(event)}/>
                                <label htmlFor="meme-input" className="pointer d-flex create-options">
                                    <FontAwesomeIcon icon={faImage} className="create-icon"/>
                                    <div className="create-p">Image</div>
                                </label>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please choose an image.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="my-2">
                            {
                                showImage ?
                                    (postType === "image" ?
                                            <img src={uploadResponse.url} alt="Upload complete"
                                                 className="image-upload"/> :
                                            <ReactPlayer url={uploadResponse.url} controls muted={true}
                                                         width="100%"
                                                         height="300px"/>
                                    )
                                    :
                                    (showProgressbar ?
                                        <ProgressBar animated striped now={uploadProgress}></ProgressBar> :
                                        <div></div>)
                            }
                            {
                                showPreview &&
                                <img src={"data:image/jpg;base64, " + base64Image} alt="Preview"
                                     className="image-upload"/>
                            }
                        </div>
                        <Modal.Footer>
                            {
                                showPreviewSpinner ?
                                    <Spinner className="float-end me-5 spinner-margin-2"/> :
                                    <>
                                        <Button variant="secondary" onClick={handleClose} className="me-3">
                                            Close
                                        </Button>
                                        <Button variant="primary" name="preview" type="submit">
                                            {
                                                isContinue ? "Continue" : "Preview"
                                            }
                                        </Button>
                                    </>
                            }
                        </Modal.Footer>
                    </Form> :
                    <Form noValidate validated={validated} onSubmit={onFormSubmit} className="mx-3">
                        <Form.Group className="mb-3" controlId="category">
                            <Form.Select name="category" required={true} ref={categoryRef}>
                                <option disabled selected value="">Select a Category</option>
                                {categories.map(category => (
                                    <option key={category.code} value={category.code}>{category.name}</option>))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please select a category.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="content">
                            <FloatingLabel label="What's on your mind?">
                                <Form.Control name="content"
                                              as="textarea"
                                              style={{height: '100px'}}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">
                                Please enter content.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="tags">
                            <TagsInput value={tags} onChange={(tags) => setTags(tags)} placeholder="# Tags"/>
                        </Form.Group>

                        <div className="image-upload my-2">
                            {
                                showImage ?
                                    (postType === "image" ?
                                            <img src={uploadResponse.url} alt="Upload complete"
                                                 className="image-upload"/> :
                                            <ReactPlayer url={uploadResponse.url} controls muted={true}
                                                         width="100%"
                                                         height="300px"/>
                                    )
                                    :
                                    (showProgressbar ?
                                        <ProgressBar animated striped now={uploadProgress}></ProgressBar> :
                                        <div></div>)
                            }
                            {
                                showPreview &&
                                <img src={"data:image/jpg;base64, " + base64Image} alt="Preview"
                                     className="image-upload"/>
                            }
                        </div>
                        <Modal.Footer>
                            <Button variant="secondary" className="me-3" onClick={() => setIsPreview(true)}>
                                Back
                            </Button>
                            {
                                showSpinner ?
                                    <Spinner animation="border"/> :
                                    <Button variant="primary" type="submit" name="create">
                                        Create
                                    </Button>
                            }
                        </Modal.Footer>
                    </Form>
            }
        </Modal.Body>

    </Modal>)
        ;
}

export default CreateMeme;