import React from 'react'
import axios from "axios";
import {Col, Container} from 'react-bootstrap'
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom'
import Post from "../../components/postup/post";
import Comments from "../../components/postup/comments";
import PostPlaceHolder from "../../components/postup/post-placeholder";
import {Helmet} from "react-helmet";
import {POST_URL, POSTS_URL} from "../../utilities/urls";
import Publish from "../../components/postup/publish";
import {toast} from "react-toastify";

const PostWithComments = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();

    const source = searchParams.get("source");
    const view = searchParams.get("view");
    const isCommentsView = view === "comments";

    const [post, setPost] = React.useState(null);

    const {
        showSignUp,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost,
        categories,
        editPostKey
    } = useOutletContext();

    React.useEffect(() => {
        fetchData();
    }, [params.id, editPostKey]);

    const fetchData = () => {
        let url;

        if (source && (source === "create" || source === "edit")) {
            url = POST_URL + `/v1/post/${params.id}`;

            axios.get(url).then((response) => {
                setPost({...response.data.data, creator: userProfile});
            }).catch((error) => {
                console.log(error);
            });
        } else {
            url = POSTS_URL + `/v1/posts/${params.id}`;

            axios.get(url).then((response) => {
                setPost(response.data.data);
            });
        }
    }

    return (
        <>
            <div id="content-page" className="content-page">
                <Container>
                    {
                        post ?
                            <Col lg={12} className="m-0 p-0">
                                <Helmet>
                                    <title>{`${post.content} - PostUp`}</title>
                                    {post.media && <link rel="image_src" content={post.media.url}/>}
                                    <link rel="canonical" href={post.url}/>
                                    <link rel="shortcut icon" href="./favicon.ico"/>
                                    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                                    <meta name="description" content={
                                        (post.likes > 0 ? `${post.likes} likes | ` : '')
                                            .concat(post.commentsCount > 0 ? `${post.commentsCount} comments | ` : '')
                                            .concat(post.content)
                                    }/>
                                    <meta property="og:title" content={post.content}/>
                                    <meta property="og:description" content={
                                        (post.likes > 0 ? `${post.likes} likes | ` : '')
                                            .concat(post.commentsCount > 0 ? `${post.commentsCount} comments` : '')
                                    }/>
                                    <meta property="og:site_name" content="PostUp"/>
                                    {post.media && <meta property="og:image" content={post.media.url}/>}
                                    <meta property="og:url" content={post.url}/>
                                    <meta property="og:type" content="article"/>
                                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                                </Helmet>
                                <Post post={post} showSignUp={showSignUp} showDeletePost={showDeletePost}
                                      preferredLanguage={preferredLanguage} userProfile={userProfile}
                                      showEditPost={showEditPost} showReportPost={showReportPost}
                                      showLanguageSelection={showLanguageSelection} categories={categories}/>
                                {/*{
                                    (userProfile && (post.type === "image" || post.type === "video")) &&
                                    <Publish postId={post.postId}/>
                                }*/}
                                <Comments postId={post.postId} reloadPost={fetchData} key={post.postId}
                                          showSignUp={showSignUp} userProfile={userProfile}
                                          isCommentsView={isCommentsView}/>
                            </Col> :
                            <PostPlaceHolder/>
                    }
                </Container>
            </div>
        </>
    )
}

export default PostWithComments
