import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import Card from '../../components/common/card'
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import SideBar from "../../components/common/side-bar";
import Header from "../../components/common/header";
import {USER_URL} from "../../utilities/urls";

const DataDeletionRequest = () => {
    const [searchParams] = useSearchParams();

    const confirmation = searchParams.get("confirmation");

    const [status, setStatus] = React.useState(null);

    const getStatus = () => {
        const url = USER_URL + `/v1/delete/status/facebook?confirmation=${confirmation}`;

        axios.get(url).then((response) => {
            setStatus(response.data.data);
        });
    }

    React.useEffect(() => {
        getStatus();
    }, []);

    return (
        <>
            <SideBar/>
            <Header/>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Card className="mt-3">
                            <Card.Body className="profile-page p-0">
                                <div className="d-flex flex-column align-items-end w-100 my-2">
                                    <div className="d-flex justify-content-evenly w-100">
                                        <div>
                                            <h3>Data Deletion Status</h3>
                                            <p className="text-center">{status}</p>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default DataDeletionRequest;