import React, {useState} from 'react'
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import Chat from "../../components/postup/chat";
import {Col, Container, Row} from "react-bootstrap";
import Card from "../../components/common/card";
import SpacesNav from "../../components/postup/spaces-nav";
import ClientSocket from "./client-socket";
import {useOutletContext} from "react-router-dom";

const Spaces = () => {
    const {
        showSignUp
    } = useOutletContext();

    const accessToken = useSelector(state => state.accessToken);
    const userProfile = useSelector(state => state.userProfile);
    const spaceNames = useSelector(state => state.spaceNames);

    const [space, setSpace] = useState(null);

    const {messages, sendMessage} = ClientSocket({
        accessToken,
        space: space?.id,
        userProfile
    });

    return (
        <>
            <Helmet>
                <title>PostUp - Spaces</title>
            </Helmet>
            {
                spaceNames && <div id="content-page" className="content-page">
                    <Container className="spaces-container">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Card.Body className="spaces-height">
                                        <Row className="h-100">
                                            <SpacesNav onSearch={e => e.preventDefault()} setSpace={setSpace}
                                                       spaceNames={spaceNames} userProfile={userProfile}
                                                       showSignUp={showSignUp} accessToken={accessToken}/>
                                            <Chat space={space} messages={messages}
                                                  sendMessage={sendMessage} userProfile={userProfile}
                                                  accessToken={accessToken}/>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    )
}

export default Spaces
