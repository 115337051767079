import React from 'react';
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {SPACES_URL} from "../../utilities/urls";

const DeleteSpace = ({show, handleClose, accessToken, spaceId, callback}) => {
    const handleDeleteSpace = () => {
        const options = {
            method: 'DELETE',
            url: SPACES_URL + `/space/${spaceId}`,
            headers: {
                'Authorization': accessToken
            }
        };

        axios.request(options).then(function (response) {
            toast.success("Deleted the space", {
                position: "bottom-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
            callback && callback();
            handleClose();
        }).catch(function (error) {
            console.error(error);
        });
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Delete Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to delete this space?</p>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDeleteSpace}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>);
}

export default DeleteSpace;