import React from 'react';
import {Button, Modal} from "react-bootstrap";

const Create = ({show, handleClose, setShowCreatePost, setShowCreateMeme}) => {
    const createPost = () => {
        handleClose();
        setShowCreatePost(true);
    };

    const createMeme = () => {
        handleClose();
        setShowCreateMeme(true);
    };

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-column text-center align-items-center">
                <div>Share your thoughts, include an image or video, or create a poll</div>
                <Button variant="primary" className="mt-1" style={{width: "200px"}}
                        onClick={() => createPost()}>Post</Button>
                <div className="mt-5">Create a meme by uploading an image and adding text</div>
                <Button variant="primary" className="mb-5 mt-1" style={{width: "200px"}}
                        onClick={() => createMeme()}>Meme</Button>
            </div>
        </Modal.Body>
    </Modal>);
}

export default Create;