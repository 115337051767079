import React from 'react';
import {Button, FloatingLabel, Form, Modal} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {POST_URL} from "../../utilities/urls";

const ReportPost = ({show, handleClose, postId}) => {
    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const options = {
            method: 'POST',
            url: POST_URL + `/v1/post/report`,
            data: {
                postId,
                reportDetails: formData.get("reportDetails")
            }
        };

        axios.request(options).then(function (response) {
            toast.success("Reported the post", {
                position: "bottom-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });
            handleClose();
        }).catch(function (error) {
            console.error(error);
        });
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Report Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={onFormSubmit} className="mx-3">
                <Form.Group className="mb-3" controlId="reportDetails">
                    <FloatingLabel label="Details">
                        <Form.Control name="reportDetails"
                                      as="textarea"
                                      required={true}
                                      style={{height: '100px'}}
                        />
                    </FloatingLabel>
                </Form.Group>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Report
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal.Body>

    </Modal>);
}

export default ReportPost;