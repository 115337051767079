import React, {useCallback, useEffect, useState} from 'react';
import {Col, Form, Modal, Spinner} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import {TENOR_KEY, tenorAxiosInstance} from "../../utilities/util";

const Tenor = ({show, handleClose}) => {
    const [query, setQuery] = useState("");
    const [gifs, setGifs] = useState([]);
    const [nextPage, setNextPage] = useState(null);

    useEffect(() => {
        if (show) {
            const timeOutId = setTimeout(() => handleSearch(), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [query, show]);

    const handleSearch = () => {
        fetchData(true);
    };

    const fetchData = (newSearch) => {
        const url = 'https://tenor.googleapis.com/v2/search';

        const options = {
            method: 'GET',
            url: url,
            params: {
                q: query || "trending",
                country: 'IN',
                key: TENOR_KEY,
                client_key: 'postup',
                limit: '32',
                media_filter: 'gif,tinygif,nanogif',
                ...(nextPage && {pos: nextPage})
            }
        };

        tenorAxiosInstance.request(options).then(function (response) {
            if (newSearch) {
                setGifs(response.data.results);
            } else {
                setGifs(gifs.concat(response.data.results));
            }
            setNextPage(response.data.next);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const [tableContainerRef, setTableContainerRef] = useState()

    const onTableContainerRefChange = useCallback(node => {
        if (node !== null) {
            setTableContainerRef(node)
        }
    }, [])

    const handleGifSelection = (gifDetails) => {
        handleClose(gifDetails);
    };

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Select a GIF</Modal.Title>
        </Modal.Header>
        <Modal.Body id="infiniteScrollBody">
            <Form className="mx-3" onSubmit={(e) => e.preventDefault()}>
                <Form.Group className="mb-3" controlId="search">
                    <Form.Control name="tags" type="text" value={query} placeholder="Search"
                                  onChange={(event) => setQuery(event.target.value)}/>
                </Form.Group>
                <div style={{maxHeight: '50vh', overflow: 'auto'}} ref={onTableContainerRefChange}>
                    {tableContainerRef &&
                        <InfiniteScroll
                            className="row tenor-container"
                            dataLength={gifs.length}
                            hasMore={true}
                            initialLoad={false}
                            next={fetchData}
                            loadMore={fetchData}
                            useWindow={false}
                            scrollableTarget={tableContainerRef}
                            loader={
                                <div className="col-sm-12 text-center">
                                    <Spinner/>
                                </div>
                            }
                            endMessage={
                                <p style={{textAlign: 'center'}}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }>
                            {
                                gifs?.map(gif =>
                                    (<Col xs={6} md={4} key={gif.id} className="text-center">
                                        <button style={{
                                            width: 125 + 'px',
                                            height: 125 + 'px',
                                            padding: 0,
                                            margin: 0,
                                            border: 'none'
                                        }}
                                                onClick={() => handleGifSelection(gif.media_formats.tinygif)}>
                                            <img style={{width: 100 + '%', height: 100 + '%'}}
                                                 src={gif.media_formats.nanogif.url}
                                                 alt="Loading" className="img-fluid w-100"/>
                                        </button>
                                    </Col>)
                                )
                            }
                        </InfiniteScroll>}
                </div>
            </Form>
        </Modal.Body>
    </Modal>);
}

export default Tenor;