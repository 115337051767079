import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <Container>
            <h1>Privacy Policy</h1>
            <p>Last updated: May 28, 2024</p>

            <h2>Our Privacy-First Promise</h2>
            <p>At PostUp, we believe in radical transparency and minimal data collection. This Privacy Policy
                explains how we protect your privacy while providing our service. Unlike traditional social
                platforms:</p>
            <ul>
                <li>We collect only essential data needed to provide the service</li>
                <li>We never sell or share your data with third parties</li>
                <li>We don't use your data for advertising or marketing</li>
                <li>We don't build behavioral profiles</li>
                <li>We don't track you across the internet</li>
                <li>We give you complete control over your data</li>
            </ul>

            <h2>What Data We Collect</h2>

            <h3>Essential Account Data</h3>
            <ul>
                <li>Email address (for account recovery and critical notifications)</li>
                <li>Username</li>
                <li>Password (encrypted)</li>
                <li>Account creation date</li>
            </ul>

            <h3>Authentication Data</h3>
            <p>When you use Google or Facebook to sign in, we receive only:</p>
            <ul>
                <li>Basic profile information (name, email)</li>
            </ul>
            <p>We explicitly DO NOT receive:</p>
            <ul>
                <li>Your contacts</li>
                <li>Your posts</li>
                <li>Your social media activities</li>
                <li>Your friends list</li>
            </ul>

            <h3>Technical Data</h3>
            <p>We temporarily store:</p>
            <ul>
                <li>IP addresses (deleted after 90 days)</li>
                <li>Basic device information</li>
                <li>Session cookies (deleted when you logout)</li>
            </ul>
            <p>Purpose: Prevent abuse and maintain security</p>

            <h3>Content Data</h3>
            <ul>
                <li>Posts, comments, and other content you choose to share</li>
                <li>You retain full ownership of this content</li>
                <li>We don't use your content for AI training or marketing</li>
            </ul>

            <h2>What We Don't Collect</h2>
            <ul>
                <li>Location data</li>
                <li>Browsing history</li>
                <li>Device identifiers</li>
                <li>Contact lists</li>
                <li>Social connections</li>
                <li>Behavioral data</li>
                <li>Marketing profiles</li>
                <li>Third-party tracking cookies</li>
            </ul>

            <h2>How We Use Your Data</h2>
            <p>We use your data only for:</p>
            <ol>
                <li>Providing core service functionality</li>
                <li>Maintaining account security</li>
                <li>Preventing abuse</li>
                <li>Complying with legal requirements</li>
            </ol>

            <p>We DO NOT use your data for:</p>
            <ul>
                <li>Advertising</li>
                <li>Marketing</li>
                <li>User profiling</li>
                <li>AI/ML training</li>
                <li>Data mining</li>
                <li>Market research</li>
            </ul>

            <h2>Your Privacy Controls</h2>

            <h3>View Your Data</h3>
            <ul>
                <li>See what information we have about you</li>
                <li>Check your privacy settings</li>
            </ul>

            <h3>Delete Your Data</h3>
            <ul>
                <li>Delete individual posts or comments</li>
                <li>Delete your entire account</li>
                <li>All data permanently removed within 30 days</li>
                <li>No hidden copies or backups retained</li>
            </ul>

            <h3>Control Your Privacy</h3>
            <ul>
                <li>Control email notifications</li>
            </ul>

            <h2>Data Security</h2>
            <p>We protect your data through:</p>
            <ul>
                <li>End-to-end encryption for sensitive data</li>
                <li>Regular security audits</li>
                <li>Limited employee access</li>
                <li>Secure data centers in India</li>
                <li>Industry-standard security protocols</li>
            </ul>

            <h2>Third-Party Services</h2>
            <p>We minimize third-party integrations to protect your privacy. When we do use third parties:</p>
            <ul>
                <li>Only essential service providers</li>
                <li>No data sharing beyond service needs</li>
                <li>Regular security reviews</li>
            </ul>

            <h3>Authentication Providers</h3>
            <p>If you use Google or Facebook sign-in:</p>
            <ul>
                <li>We only receive basic profile data</li>
                <li>You can revoke access anytime</li>
                <li>Review their privacy policies:</li>
                <ul>
                    <li><a href="https://policies.google.com/privacy">Google Privacy Policy</a></li>
                    <li><a href="https://www.facebook.com/privacy/policy/">Facebook Privacy Policy</a></li>
                </ul>
            </ul>

            <h2>Cookies</h2>
            <p>We use only essential cookies:</p>
            <ul>
                <li>Session cookies for authentication</li>
                <li>No tracking cookies</li>
            </ul>

            <h2>Legal Compliance</h2>

            <h3>Data Requests</h3>
            <p>We only share data when legally required:</p>
            <ul>
                <li>Valid court orders</li>
                <li>Legal warrants</li>
                <li>Emergency situations</li>
            </ul>
            <p>We will notify you via email unless legally prohibited.</p>

            <h3>Data Retention</h3>
            <ul>
                <li>Account data: Until you delete your account</li>
                <li>IP logs: 90 days</li>
                <li>Technical logs: 30 days</li>
                <li>Content: Until you delete it</li>
            </ul>

            <h3>Children's Privacy</h3>
            <ul>
                <li>Service not intended for users under 13</li>
                <li>We don't knowingly collect data from children</li>
                <li>We delete any discovered underage accounts</li>
            </ul>

            <h2>Changes to Privacy Policy</h2>
            <ul>
                <li>30 days notice for material changes</li>
                <li>Email notification of updates</li>
                <li>Changes effective after notice period</li>
            </ul>

            <h2>Contact Us</h2>
            <div>
                <p>For privacy questions or concerns:</p>
                <ul>
                    <li>Email: <a href="mailto:support@postup.in">support@postup.in</a></li>
                    <li>Response within 24 hours</li>
                    <li>Full support for privacy requests</li>
                    <li>No automated responses</li>
                </ul>
            </div>

            <h2>Your Rights</h2>
            <p>You have the right to:</p>
            <ol>
                <li>Access your data</li>
                <li>Correct your data</li>
                <li>Delete your data</li>
                <li>Restrict processing</li>
                <li>Object to processing</li>
                <li>Withdraw consent</li>
            </ol>
            <p>All requests handled within 30 days, usually sooner.</p>
        </Container>
    )
}

export default PrivacyPolicy
