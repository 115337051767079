import {createSlice} from '@reduxjs/toolkit';

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: [],
    reducers: {
        setCategories: (state, action) => {
            return action.payload
        }
    },
});

export const {setCategories} = categoriesSlice.actions

export const spaceNamesSlice = createSlice({
    name: 'spaceNames',
    initialState: [],
    reducers: {
        setSpaceNames: (state, action) => {
            return action.payload
        }
    },
});

export const {setSpaceNames} = spaceNamesSlice.actions

export const languagesSlice = createSlice({
    name: 'languages',
    initialState: [],
    reducers: {
        setLanguages: (state, action) => {
            return action.payload
        }
    },
});

export const {setLanguages} = languagesSlice.actions

export const preferredLanguageSlice = createSlice({
    name: 'preferredLanguage',
    initialState: null,
    reducers: {
        setPreferredLanguage: (state, action) => {
            return action.payload
        }
    },
});

export const {setPreferredLanguage} = preferredLanguageSlice.actions

export const topCreatorsSlice = createSlice({
    name: 'topCreators',
    initialState: [],
    reducers: {
        setTopCreators: (state, action) => {
            return action.payload
        }
    },
});

export const {setTopCreators} = topCreatorsSlice.actions

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: null,
    reducers: {
        setUserProfile: (state, action) => {
            return action.payload
        },
        updateLikes: (state, action) => {
            if (action.payload.like) {
                state.likes[action.payload.postId] = action.payload.like
            } else {
                delete state.likes[action.payload.postId];
            }
        },
        updateVotes: (state, action) => {
            state.votes[action.payload.postId] = action.payload.option
        },
        updateFlags: (state, action) => {
            state.flags[action.payload.flag] = action.payload.value
        }
    },
});

export const {setUserProfile, updateLikes, updateVotes, updateFlags} = userProfileSlice.actions

export const accessTokenSlice = createSlice({
    name: 'accessToken',
    initialState: null,
    reducers: {
        setAccessToken: (state, action) => {
            return action.payload
        }
    },
});

export const {setAccessToken} = accessTokenSlice.actions

export const dismissWelcomeSlice = createSlice({
    name: 'dismissWelcome',
    initialState: null,
    reducers: {
        setDismissWelcome: (state, action) => {
            return action.payload
        }
    },
});

export const {setDismissWelcome} = dismissWelcomeSlice.actions

const reducers = {
    categories: categoriesSlice.reducer,
    spaceNames: spaceNamesSlice.reducer,
    languages: languagesSlice.reducer,
    preferredLanguage: preferredLanguageSlice.reducer,
    topCreators: topCreatorsSlice.reducer,
    userProfile: userProfileSlice.reducer,
    accessToken: accessTokenSlice.reducer,
    dismissWelcome: dismissWelcomeSlice.reducer
};

export default reducers;