import React, {useState} from 'react';
import {Button, FloatingLabel, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {SPACES_URL} from "../../utilities/urls";

const EditSpace = ({show, handleClose, accessToken, space}) => {
    const [validated, setValidated] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const onFormSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        const formData = new FormData(e.target);

        let isValid = true;
        if (!formData.get("name").trim()) {
            isValid = false;
        }

        setValidated(true);

        if (!isValid) {
            return
        }

        setShowSpinner(true);

        const options = {
            method: 'PATCH',
            url: SPACES_URL + '/space',
            headers: {
                'Authorization': accessToken
            },
            data: {
                id: space.id,
                newName: formData.get("name").trim()
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={onFormSubmit} className="mx-3">
                    <Form.Group className="mb-3" controlId="name">
                        <FloatingLabel controlId="name" label="New name">
                            <Form.Control
                                name="name"
                                as="input"
                                placeholder="Enter a new name"
                                required={true}
                                defaultValue={space.name}
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">
                            Please enter a name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {
                            showSpinner ? <Spinner animation="border" className="create-spinner"/> :
                                <Button variant="primary" type="submit" name="udpate">
                                    Update
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditSpace;