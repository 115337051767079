import React from 'react'
import {Container} from 'react-bootstrap'

const TermsOfService = () => {
    return (
        <>
            <Container>
                <h1>End-User License Agreement ("EULA") and Terms of Service</h1>

                <p>Last updated: May 28, 2024</p>

                <section>
                    <h2>Our Privacy-First Commitment</h2>
                    <p>At PostUp, we believe your social experience should be yours alone. Unlike other platforms,
                        we:</p>
                    <ul>
                        <li>Do not track your activities for advertising purposes</li>
                        <li>Do not build user profiles for marketing</li>
                        <li>Do not feed your data to AI systems</li>
                        <li>Do not engage in excessive data collection</li>
                        <li>Do not use intrusive algorithms to manipulate your experience</li>
                    </ul>
                </section>

                <section>
                    <h2>Interpretation and Definitions</h2>
                    <p>The words of which the initial letter is capitalized have meanings defined under the following
                        conditions.</p>

                    <h3>Definitions</h3>
                    <ul>
                        <li><strong>Service:</strong> PostUp application, website (https://postup.in), and related
                            online properties owned or controlled by PostUp
                        </li>
                        <li><strong>Company/We/Us/Our:</strong> Refers to PostUp</li>
                        <li><strong>You:</strong> The individual or entity using the Service</li>
                        <li><strong>Content:</strong> Text, images, or other information that you post, upload, or make
                            available through the Service
                        </li>
                        <li><strong>Device:</strong> Any device that can access the Service (computer, cellphone,
                            tablet, etc.)
                        </li>
                        <li><strong>Country:</strong> India</li>
                        <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common
                            control with a party, where "control" means ownership of 50% or more of the shares, equity
                            interest, or other securities
                        </li>
                        <li><strong>Website:</strong> PostUp, accessible from https://postup.in</li>
                        <li><strong>Application:</strong> The software program provided by the Company, named PostUp
                        </li>
                        <li><strong>Third-party Social Media Service:</strong> Any services or content provided by a
                            third-party that may be displayed, included, or made available by the Service
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>Acknowledgment</h2>
                    <p>By downloading, installing, accessing, or using any part of the Service, you indicate that
                        you:</p>
                    <ul>
                        <li>Have read, understood, and agreed to be bound by this EULA</li>
                        <li>Are over the age of 18</li>
                        <li>Accept our Privacy Policy</li>
                        <li>Agree to our data handling practices</li>
                    </ul>
                </section>

                <section>
                    <h2>Privacy-First Features</h2>

                    <h3>Data Collection and Usage</h3>
                    <ul>
                        <li>We collect only the minimum data necessary to provide our service</li>
                        <li>Your data is never sold, shared, or used for advertising purposes</li>
                        <li>You maintain full control over your data and can delete it at any time</li>
                        <li>We do not use tracking cookies or similar technologies for marketing</li>
                        <li>We do not create behavioral profiles of our users</li>
                        <li>Any analytics we perform are strictly anonymous and aggregate-level</li>
                    </ul>

                    <h3>User Control</h3>
                    <ul>
                        <li>You can download your data at any time</li>
                        <li>You can delete your account and all associated data</li>
                        <li>We provide clear information about what limited data we do collect</li>
                        <li>You can opt out of any optional data collection</li>
                    </ul>

                    <h3>IP Address and Log Data</h3>
                    <ul>
                        <li>We temporarily store IP addresses solely for:
                            <ul>
                                <li>Security purposes</li>
                                <li>Preventing abuse</li>
                                <li>Complying with legal requirements</li>
                            </ul>
                        </li>
                        <li>IP logs are automatically deleted after 90 days</li>
                        <li>We do not use IP addresses for tracking or profiling</li>
                        <li>IP addresses are never shared with third parties except when legally required</li>
                        <li>We may block IP addresses associated with abusive behavior</li>
                    </ul>

                    <h3>Third-Party Authentication</h3>
                    <ul>
                        <li>We support sign-in through Google and Facebook for convenience</li>
                        <li>When using third-party authentication:
                            <ul>
                                <li>We only receive basic profile information (name, email)</li>
                                <li>We do not access your social media posts or contacts</li>
                                <li>We do not post to your social media accounts</li>
                                <li>We do not track your activities on these platforms</li>
                                <li>You can disconnect these services at any time</li>
                            </ul>
                        </li>
                        <li>Your use of Google or Facebook authentication is subject to their respective privacy
                            policies
                        </li>
                        <li>We recommend reviewing their privacy policies:
                            <ul>
                                <li><a href="https://policies.google.com/privacy">Google Privacy Policy</a></li>
                                <li><a href="https://www.facebook.com/privacy/policy/">Facebook Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>License</h2>
                    <h3>Scope of License</h3>
                    <ul>
                        <li>The Company grants you a revocable, non-exclusive, non-transferable, limited license</li>
                        <li>License is for personal, non-commercial use only</li>
                        <li>Must comply with all terms of this Agreement</li>
                    </ul>
                </section>

                <section>
                    <h2>Account Creation and Usage</h2>
                    <ul>
                        <li>You must be over 18 to use the Service</li>
                        <li>Provide accurate information during account creation</li>
                        <li>You are responsible for maintaining account security</li>
                        <li>Multiple accounts are not permitted</li>
                        <li>We reserve the right to terminate accounts that violate our terms</li>
                    </ul>
                </section>

                <section>
                    <h2>Content Rights, Responsibilities, and Liability</h2>

                    <h3>Your Content</h3>
                    <ul>
                        <li>You retain ownership of your content</li>
                        <li>You grant us limited rights to display your content within the Service</li>
                        <li>We do not claim ownership of your content</li>
                        <li>We do not use your content for AI training or marketing</li>
                    </ul>

                    <h3>Content Liability and Legal Compliance</h3>
                    <ul>
                        <li>You are solely responsible for all content you upload, post, or share</li>
                        <li>You must have all necessary rights and permissions for the content you post</li>
                        <li>You agree to indemnify and hold PostUp harmless from any claims arising from your content
                        </li>
                        <li>You represent that your content does not:
                            <ul>
                                <li>Violate any intellectual property rights</li>
                                <li>Infringe on privacy or publicity rights</li>
                                <li>Violate any applicable laws or regulations</li>
                                <li>Contain defamatory or libelous material</li>
                            </ul>
                        </li>
                        <li>We reserve the right to:
                            <ul>
                                <li>Remove content that violates our terms</li>
                                <li>Cooperate with law enforcement when legally required</li>
                                <li>Respond to valid legal requests for information</li>
                                <li>Terminate accounts posting illegal content</li>
                                <li>Take appropriate legal action against violators</li>
                            </ul>
                        </li>
                    </ul>

                    <h3>DMCA and Copyright Claims</h3>
                    <ul>
                        <li>We respect intellectual property rights</li>
                        <li>We will respond to legitimate DMCA takedown notices</li>
                        <li>False copyright claims may result in account termination</li>
                        <li>Contact support@postup.in for copyright-related issues</li>
                    </ul>

                    <h3>Content Monitoring and Removal</h3>
                    <ul>
                        <li>We do not pre-screen content</li>
                        <li>We may review content after posting</li>
                        <li>We may remove content that violates our terms</li>
                        <li>We are not liable for any content posted by users</li>
                        <li>We may preserve content related to legal investigations</li>
                        <li>Users can report violations using [⋯] or [▾] in posts/comments</li>
                    </ul>

                    <h3>Legal Requests and Compliance</h3>
                    <ul>
                        <li>We will comply with valid legal requests for information</li>
                        <li>We will notify users of legal requests when permitted</li>
                        <li>We may share information in response to:
                            <ul>
                                <li>Court orders</li>
                                <li>Subpoenas</li>
                                <li>Legal warrants</li>
                                <li>National security letters</li>
                                <li>Emergency situations involving imminent harm</li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>Third-Party Services</h2>
                    <ul>
                        <li>Service may include links to third-party websites or services</li>
                        <li>We are not responsible for third-party content or services</li>
                        <li>Your use of third-party services is subject to their terms</li>
                        <li>We recommend reviewing third-party terms and privacy policies</li>
                    </ul>
                </section>

                <section>
                    <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                    <ul>
                        <li>Service provided without warranties</li>
                        <li>We do not guarantee uninterrupted service</li>
                        <li>We are not responsible for third-party content</li>
                        <li>No warranty of merchantability or fitness for a particular purpose</li>
                        <li>Some jurisdictions may not allow certain limitations</li>
                    </ul>
                </section>

                <section>
                    <h2>Additional Legal Protections</h2>

                    <h3>Limitation of Liability</h3>
                    <p>PostUp is not liable for:</p>
                    <ul>
                        <li>User-generated content</li>
                        <li>Disputes between users</li>
                        <li>Unauthorized access to your account</li>
                        <li>Loss of content or data</li>
                        <li>Third-party links or services</li>
                    </ul>
                    <p>Maximum liability limited to fees paid (if any) for service</p>

                    <h3>Indemnification</h3>
                    <p>You agree to indemnify and hold PostUp harmless from any claims, damages, or expenses arising
                        from:</p>
                    <ul>
                        <li>Your content</li>
                        <li>Your use of the service</li>
                        <li>Your violation of these terms</li>
                        <li>Your interaction with other users</li>
                        <li>Your violation of any laws or regulations</li>
                    </ul>
                </section>

                <section>
                    <h2>Dispute Resolution</h2>
                    <ul>
                        <li>Disputes resolved through individual arbitration</li>
                        <li>No class actions</li>
                        <li>Contact support@postup.in for concerns</li>
                    </ul>
                </section>

                <section>
                    <h2>Termination</h2>
                    <ul>
                        <li>You can terminate your account at any time</li>
                        <li>We will delete your data within 30 days of account termination</li>
                        <li>We may terminate accounts that violate our terms</li>
                        <li>Contact support@postup.in for account deletion</li>
                    </ul>
                </section>

                <section>
                    <h2>Changes to Terms</h2>
                    <ul>
                        <li>We will provide 30 days notice for material changes</li>
                        <li>Changes will not compromise our privacy-first commitment</li>
                        <li>You will be notified of any changes via email</li>
                    </ul>
                </section>

                <section>
                    <h2>Governing Law</h2>
                    <ul>
                        <li>Laws of India govern this agreement</li>
                        <li>Service intended for use in India</li>
                        <li>Users outside India use the service at their own discretion</li>
                    </ul>
                </section>

                <section>
                    <h2>Severability and Waiver</h2>
                    <ul>
                        <li>If any provision is found unenforceable, other provisions remain in effect</li>
                        <li>Failure to enforce any right does not waive that right</li>
                    </ul>
                </section>

                <section>
                    <h2>PostUp Rules</h2>
                    <ol>
                        <li>No harmful content (violence, gore, offensive material)</li>
                        <li>No adult content</li>
                        <li>No deceptive practices</li>
                        <li>No personal information sharing</li>
                        <li>No illegal activities</li>
                        <li>No copyright violations</li>
                        <li>No spam</li>
                        <li>No multiple accounts</li>
                        <li>No manipulation of platform features</li>
                        <li>Report violations using [⋯] or [▾] in posts/comments</li>
                    </ol>
                    <p>Violation of these rules may result in permanent account termination.</p>
                </section>

                <section class="contact">
                    <h2>Contact Information</h2>
                    <p>If you have any questions about these terms or the Service, please contact us:</p>
                    <p>Email: <a href="mailto:support@postup.in">support@postup.in</a></p>
                </section>
            </Container>
        </>
    )
}

export default TermsOfService
