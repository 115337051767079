import React, {useRef, useState} from 'react';
import Card from "../common/card";
import axios from "axios";
import Comment from "./comment";
import CreateComment from "./create-comment";
import {COMMENTS_URL} from "../../utilities/urls";
import InfiniteScroll from "react-infinite-scroll-component";
import {NavDropdown} from "react-bootstrap";
import CommentPlaceHolder from "./comment-placeholder";
import PostPlaceholder from "./post-placeholder";

const Comments = ({postId, showSignUp, closeSignUp, userProfile, isCommentsView}) => {
    const commentsContainerRef = useRef();
    const commentsRef = useRef();

    const [comments, setComments] = useState(null);
    const [createdComments, setCreatedComments] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [sortText, setSortText] = useState("Top Comments");
    const [sort, setSort] = useState("hot");
    const [perPage, setPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    React.useEffect(() => {
        if (comments === null) {
            fetchData(1);
        }
    }, []);

    React.useEffect(() => {
        if (comments !== null) {
            setIsLoading(true);
            fetchData(nextPage);
        }
    }, [sort]);

    React.useEffect(() => {
        if (isCommentsView && comments && isFirstLoad) {
            commentsContainerRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"
            });

            setIsFirstLoad(false);
        }
    }, [comments]);

    const fetchData = (page) => {
        setIsLoading(true);
        const options = {
            method: 'GET',
            url: COMMENTS_URL + `/v1/comment?page=${page}&size=${perPage}&sort=${sort}`,
            params: {
                postId: postId
            }
        };

        axios.request(options).then(function (response) {
            if (perPage !== 10) {
                setCreatedComments([]);
            }

            if (comments === null || perPage !== 10) {
                setComments(response.data.data.comments);
            } else {
                setComments(comments.concat(response.data.data.comments));
            }

            setNextPage(response.data.data.page.nextPage);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.error(error);
        });

    };

    const addToComments = (comment) => {
        createdComments.unshift(comment);
        setCreatedComments(createdComments);

        setPerPage(comments.length + 10 + createdComments.length);
        setNextPage(1);
    }

    const handleSort = (selectedSort) => {
        if (selectedSort === sort) {
            return;
        }

        setSort(selectedSort);
        setNextPage(1);
        comments.length = 0;

        if (selectedSort === "hot") {
            setSortText("Top Comments")
        } else {
            setSortText("New Comments")
        }
    }

    return (
        <div ref={commentsContainerRef}
             style={commentsRef?.current?.clientHeight && {minHeight: `${commentsContainerRef.current.clientHeight}px`}}>
            <CreateComment postId={postId} addToComments={addToComments} showSignUp={showSignUp}
                           userProfile={userProfile}/>
            {
                isLoading ?
                    <PostPlaceholder/> :
                    <>
                        {
                            (comments?.length > 0 || createdComments.length > 0) ?
                                <div ref={commentsRef}>
                                    <NavDropdown title={sortText} menuVariant="dark"
                                                 className="d-flex justify-content-end">
                                        <NavDropdown.Item onClick={() => handleSort("hot")}>Top
                                            Comments</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleSort("new")}>New
                                            Comments</NavDropdown.Item>
                                    </NavDropdown>
                                    <Card className="card-block card-stretch card-height">
                                        <Card.Body>
                                            <ul className="post-comments list-inline p-0 m-0">
                                                {
                                                    createdComments.map(comment => <Comment comment={comment}
                                                                                            key={comment.commentId}
                                                                                            showSignUp={showSignUp}
                                                                                            closeSignUp={closeSignUp}
                                                                                            userProfile={userProfile}/>)
                                                }
                                                {
                                                    comments &&
                                                    <InfiniteScroll
                                                        dataLength={comments.length}
                                                        next={() => fetchData(nextPage)}
                                                        hasMore={nextPage != null}
                                                        loader={<CommentPlaceHolder/>}
                                                    >
                                                        {
                                                            comments.map(comment => <Comment comment={comment}
                                                                                             key={comment.commentId}
                                                                                             showSignUp={showSignUp}
                                                                                             closeSignUp={closeSignUp}
                                                                                             userProfile={userProfile}/>)
                                                        }
                                                    </InfiniteScroll>
                                                }
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div> :
                                <div className="text-center no-data-message">No comments yet—add yours and lead the way!</div>
                        }
                    </>
            }
        </div>
    );
};

export default Comments;