import React from 'react';
import Card from "../common/card";
import {Placeholder} from "react-bootstrap";

const PostPlaceHolder = ({style}) => {
    return (
        <Card className="card-block card-stretch" id={Math.random()}>
            <Card.Body>
                <div style={style}>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={3}/>
                    </Placeholder>
                    <div>
                        <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={10}/>
                        </Placeholder>
                    </div>
                    <div style={{height: 20 + 'rem'}}>
                        <Placeholder as={Card.Img} animation="glow">
                            <Placeholder xs={10}/>
                        </Placeholder>
                    </div>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={3}/>
                    </Placeholder>
                </div>
            </Card.Body>
        </Card>
    )
};

export default PostPlaceHolder;