import React from 'react';
import Card from "../common/card";
import {Placeholder} from "react-bootstrap";

const CommentPlaceHolder = () => {
    return (
        <Card className=" card-block card-stretch card-height" id={Math.random()}>
            <Card.Body>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={3}/>
                </Placeholder>
                <div>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={10}/>
                    </Placeholder>
                </div>
            </Card.Body>
        </Card>
    )
};

export default CommentPlaceHolder;