import React from 'react';
import Card from "../common/card";
import {Button} from "react-bootstrap";
import {POSTS_URL} from "../../utilities/urls";
import axios from "axios";
import {updateVotes} from "../../store/app/reducers";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";

const Poll = ({post, userProfile, showSignUp}) => {
    const dispatch = useDispatch();

    const [votes, setVotes] = React.useState(post?.votes ? post.votes : {});

    const handleVote = (option) => {
        if (userProfile) {
            vote(option);
        } else {
            showSignUp(() => vote(option));
        }
    }

    const vote = (option) => {
        const options = {
            method: 'PATCH',
            url: POSTS_URL + `/v1/posts/${post.postId}/vote?option=${option}`,
        };

        axios.request(options).then(function (response) {
            setVotes(response.data.data);
            dispatch(updateVotes({postId: post.postId, option: option}));
        }).catch(function (error) {
            console.error(error);
        });
    }

    let total = 0;
    Object.values(votes).forEach(num => {
        total += num;
    })

    return (
        <>
            {
                post.pollOptions.map((option, index) =>
                    <Card key={index}>
                        <Card.Body className="poll-card-body">
                            <div className="poll-container">
                                <div style={{zIndex: 1}}>
                                    <h5 className="poll-content">{option}{total !== 0 && ` - ${votes[index]}/${total}`}</h5>
                                    {
                                        !(userProfile?.votes[post.postId] !== undefined) &&
                                        <div className="poll-button">
                                            <Button onClick={() => handleVote(index)}>Vote</Button>
                                        </div>
                                    }
                                    {
                                        (userProfile?.votes[post.postId] === index) &&
                                        <div className="poll-button">
                                            <FontAwesomeIcon icon={faCircleCheck}
                                                             style={{fontSize: "1.5rem", color: "var(--bs-green)"}}/>
                                        </div>
                                    }
                                </div>
                                {
                                    total !== 0 &&
                                    <div className="poll-bar"
                                         style={{width: Math.round((votes[index] / total) * 100)}}/>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                )
            }
        </>
    );
};

export default Poll;