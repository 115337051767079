import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {USER_URL} from "../../utilities/urls";
import {Link} from "react-router-dom";

const PasswordChangeModal = ({show, handleClose, userId, showPasswordSpinner}) => {
    const [changePwdCurrentIndex, setChangePwdCurrentIndex] = useState(0);

    const [changePwdInputRefsArray] = useState(() =>
        Array.from({length: 6}, () => createRef())
    );

    const [changePwdLetters, setChangePwdLetters] = useState(() =>
        Array.from({length: 6}, () => "")
    );

    const [showResendSpinner, setShowResendSpinner] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleKeyPress = (e) => {
        const value = e.key;
        const index = Number(e.target.name[0]);
        if (/\b\d\b/gm.test(value)) {
            setChangePwdLetters((changePwdLetters) =>
                changePwdLetters.map((letter, letterIndex) =>
                    letterIndex === index ? value : letter
                )
            );
        } else if (value === "") {
            setChangePwdLetters((changePwdLetters) =>
                changePwdLetters.map((letter, letterIndex) =>
                    letterIndex === index ? "" : letter
                )
            );
        }

        if (e?.target?.name?.includes("pwdCode")) {
            setChangePwdCurrentIndex((prevIndex) => {
                if (!/\b\d\b/gm.test(e.key)) {
                    return prevIndex;
                }

                if (prevIndex < 5) {
                    const nextIndex = prevIndex + 1;
                    const nextInput = changePwdInputRefsArray?.[nextIndex]?.current;
                    nextInput.focus();
                    nextInput.select();

                    return nextIndex;
                } else {
                    return prevIndex;
                }
            });
        }
    };

    useEffect(() => {
        // focus the first input initially
        if (changePwdInputRefsArray?.[0]?.current) {
            changePwdInputRefsArray?.[0]?.current?.focus();
        }

        // add the event listener for keyup keyboard event
        window.addEventListener("keyup", handleKeyPress, false);

        // remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const onPasswordChangeFormSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();
        const formData = new FormData(e.target);

        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/password',
            data: {
                userId: userId,
                password: formData.get("password"),
                verificationCode: changePwdLetters.reduce((accumulator, currentValue) => accumulator + currentValue, "")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    const resendCode = () => {
        setShowResendSpinner(true);
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/password/email',
            data: {
                userId: userId
            }
        };

        axios.request(options).then(function (response) {
            setShowResendSpinner(false);
        }).catch(function (error) {
            setShowResendSpinner(false);
            console.error(error);
        });
    }

    return (
        <Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton={true}>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="sign-in-from d-flex justify-content-center">
                    {
                        showPasswordSpinner ? <Spinner/> :
                            <Form onSubmit={onPasswordChangeFormSubmit}>
                                <Form.Group className="form-group">
                                    <Form.Label>Please enter the 6-digit verification code we sent to your email. This
                                        code expires in 15 minutes.</Form.Label>
                                    <div className="d-flex justify-content-center">
                                        {changePwdInputRefsArray.map((ref, index) => {
                                            return (
                                                <Form.Control ref={ref}
                                                              key={index}
                                                              type="text"
                                                              pattern="[0-9]"
                                                              inputMode="numeric"
                                                              className="mb-0 email-code"
                                                              name={`${index}pwdCode`}
                                                              autoFocus={index === 0}
                                                              required={true}
                                                              onClick={(e) => {
                                                                  setChangePwdCurrentIndex(index);
                                                                  e.target.select();
                                                              }}
                                                              value={changePwdLetters[index]}/>
                                            );
                                        })}
                                    </div>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type="password" className="mb-0" name="password"
                                                  placeholder="Enter Password" minLength={8}/>
                                </Form.Group>
                                <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                                    {
                                        showSpinner ?
                                            <>
                                                <div></div>
                                                <Spinner className="me-3 spinner-margin"/>
                                            </> :
                                            showResendSpinner ?
                                                <Spinner className="ms-4 spinner-margin"/> :
                                                <>
                                                    <Link to="#" onClick={resendCode} style={{color: "var(--bs-primary)"}}>
                                                        Resend Code?</Link>
                                                    <Button variant="primary" type="submit">Save</Button>
                                                </>
                                    }
                                </div>
                            </Form>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PasswordChangeModal;