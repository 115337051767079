import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {USER_URL} from "../../utilities/urls";
import {Link} from "react-router-dom";

const EmailUpdateModal = ({show, handleClose, emailAddress}) => {

    const [changeEmailCurrentIndex, setChangeEmailCurrentIndex] = useState(0);

    const [changeEmailInputRefsArray] = useState(() =>
        Array.from({length: 6}, () => createRef())
    );

    const [changeEmailLetters, setChangeEmailLetters] = useState(() =>
        Array.from({length: 6}, () => "")
    );

    const [showSpinner, setShowSpinner] = useState(false);
    const [showResendSpinner, setShowResendSpinner] = useState(false);

    const handleKeyPress = (e) => {
        const value = e.key;
        const index = Number(e.target.name[0]);
        if (/\b\d\b/gm.test(value)) {
            setChangeEmailLetters((changeEmailLetters) =>
                changeEmailLetters.map((letter, letterIndex) =>
                    letterIndex === index ? value : letter
                )
            );
        } else if (value === "") {
            setChangeEmailLetters((changeEmailLetters) =>
                changeEmailLetters.map((letter, letterIndex) =>
                    letterIndex === index ? "" : letter
                )
            );
        }

        if (e?.target?.name?.includes("emailChgCode")) {
            setChangeEmailCurrentIndex((prevIndex) => {
                if (!/\b\d\b/gm.test(e.key)) {
                    return prevIndex;
                }

                if (prevIndex < 5) {
                    const nextIndex = prevIndex + 1;
                    const nextInput = changeEmailInputRefsArray?.[nextIndex]?.current;
                    nextInput.focus();
                    nextInput.select();

                    return nextIndex;
                } else {
                    return prevIndex;
                }
            });
        }
    };

    useEffect(() => {
        // focus the first input initially
        if (changeEmailInputRefsArray?.[0]?.current) {
            changeEmailInputRefsArray?.[0]?.current?.focus();
        }

        // add the event listener for keyup keyboard event
        window.addEventListener("keyup", handleKeyPress, false);

        // remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const onFormSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();

        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/email',
            data: {
                emailAddress: emailAddress,
                verificationCode: changeEmailLetters.reduce((accumulator, currentValue) => accumulator + currentValue, "")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    const resendCode = () => {
        setShowResendSpinner(true);
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/email/email',
            data: {
                emailAddress: emailAddress
            }
        };

        axios.request(options).then(function (response) {
            setShowResendSpinner(false);
        }).catch(function (error) {
            setShowResendSpinner(false);
            console.error(error);
        });
    }

    return (
        <Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton={true}>
                <Modal.Title>Change email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="sign-in-from">
                    <Form onSubmit={onFormSubmit} className="mt-4">
                        <Form.Group className="form-group mt-5">
                            <Form.Control type="email" className="mb-0" name="emailAddress" required={true} disabled
                                          value={emailAddress}/>
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Please enter the 6-digit verification code we sent to the above email. This
                                code expires in 15 minutes.</Form.Label>
                            <div className="d-flex justify-content-center">
                                {changeEmailInputRefsArray.map((ref, index) => {
                                    return (
                                        <Form.Control ref={ref}
                                                      key={index}
                                                      type="text"
                                                      pattern="[0-9]"
                                                      inputMode="numeric"
                                                      className="mb-0 email-code"
                                                      name={`${index}emailChgCode`}
                                                      required={true}
                                                      onClick={(e) => {
                                                          setChangeEmailCurrentIndex(index);
                                                          e.target.select();
                                                      }}
                                                      value={changeEmailLetters[index]}/>
                                    );
                                })}
                            </div>
                        </Form.Group>
                        <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                            {
                                showSpinner ?
                                    <>
                                        <div></div>
                                        <Spinner className="me-4 spinner-margin"/>
                                    </> :
                                    showResendSpinner ?
                                        <Spinner className="ms-4 spinner-margin"/> :
                                        <>
                                            <Link to="#" onClick={resendCode} style={{color: "var(--bs-primary)"}}>
                                                Resend Code?</Link>
                                            <Button variant="primary" type="submit">Submit</Button>
                                        </>
                            }
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EmailUpdateModal;