import React, {useRef} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setPreferredLanguage, setUserProfile} from "../../store/app/reducers";
import {USER_URL} from "../../utilities/urls";

const LanguageSelection = ({show, handleClose, callBack}) => {
    const languageRef = useRef();
    const dispatch = useDispatch();

    const userProfile = useSelector(state => state.userProfile);
    const languages = useSelector(state => state.languages);
    const preferredLanguage = useSelector(state => state.preferredLanguage);

    const onFormSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const language = formData.get("language");

        if (userProfile) {
            const options = {
                method: 'PATCH',
                url: USER_URL + '/v1/user',
                data: {
                    preferredLanguage: language
                }
            };

            axios.request(options).then(function (response) {
                dispatch(setPreferredLanguage(language));
                dispatch(setUserProfile(response.data.data));
                handleClose();
                callBack && callBack(language);
            }).catch(function (error) {
                console.error(error);
            });
        } else {
            dispatch(setPreferredLanguage(formData.get("language")));
            handleClose();
            callBack && callBack(language);
        }
    }

    return (
        <Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Translation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onFormSubmit} className="mx-3">
                    <Form.Group className="mb-3" controlId="language">
                        <Form.Label>Language</Form.Label>
                        <Form.Select name="language" required={true} ref={languageRef}>
                            {!preferredLanguage && <option disabled selected value="">Select a Language</option>}
                            {languages.map(language => (
                                <option key={language.code} value={language.code} selected={language.code === preferredLanguage}>{language.name}</option>))}
                        </Form.Select>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default LanguageSelection;