import React, {useState} from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import Card from '../../components/common/card'
import {useDispatch} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import PostPlaceholder from "../../components/postup/post-placeholder";
import Post from "../../components/postup/post";
import axios from "axios";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {POSTS_URL, USER_URL} from "../../utilities/urls";
import {Helmet} from "react-helmet";
import {setUserProfile} from "../../store/app/reducers";
import CreatorProfilePlaceHolder from "../../components/postup/creator-profile-placeholder";

const Creator = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const params = useParams();
    const userId = params.id;

    const [creatorUserProfile, setCreatorUserProfile] = useState(null);
    const [posts, setPosts] = useState([]);
    const [nextPage, setNextPage] = useState(1);

    const {
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showSignUp,
        showReportPost,
        categories
    } = useOutletContext();

    React.useEffect(() => {
        getUserProfile();
        posts.length = 0;
        fetchData(1);
    }, [userId]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?creator=${userId}&page=${page}`;

        axios.get(url).then((response) => {
            setPosts(posts.concat(response.data.data.posts));
            setNextPage(response.data.data.page.nextPage);
        });
    }

    const getUserProfile = (page) => {
        const url = USER_URL + `/v1/user/${userId}`;

        axios.get(url).then((response) => {
            if (!response.data?.data) {
                return navigate('/error/400');
            }
            setCreatorUserProfile(response.data.data);
        });
    }

    const followCreator = () => {
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/user',
            data: {
                followCreator: userId
            }
        };

        axios.request(options).then(function (response) {
            dispatch(setUserProfile(response.data.data));
        }).catch(function (error) {
            console.error(error);
        });
    };

    const unfollowCreator = () => {
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/user',
            data: {
                unfollowCreator: userId
            }
        };

        axios.request(options).then(function (response) {
            dispatch(setUserProfile(response.data.data));
        }).catch(function (error) {
            console.error(error);
        });
    };

    const handleFollow = () => {
        if (userProfile) {
            followCreator();
        } else {
            showSignUp(followCreator);
        }
    };

    return (
        <>
            <Helmet>
                <title>PostUp</title>
                <link rel="image_src" content="https://media.postup.in/images/img_src.jpg"/>
                <link rel="canonical" href="https://postup.in/"/>
                <link rel="shortcut icon" href="./favicon.ico"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                <meta name="description" content="The ultimate social hub!"/>
                <meta property="og:title" content="PostUp"/>
                <meta property="og:description" content="The ultimate social hub!"/>
                <meta property="og:site_name" content="PostUp"/>
                <meta property="og:image" content="https://media.postup.in/images/img_src.jpg"/>
                <meta property="og:url" content="https://postup.in/"/>
                <meta property="og:type" content="website"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            </Helmet>
            <Container key={userId}>
                <Row>
                    <Col lg={12}>
                        <Card className="mt-3">
                            <Card.Body className="profile-page p-0">
                                <div className="d-flex flex-column align-items-end w-100 my-2">
                                    <div className="d-flex justify-content-evenly w-100">
                                        {
                                            creatorUserProfile ?
                                                <>
                                                    <div>
                                                        <div className="text-center">
                                                            <img src={creatorUserProfile?.avatarUrl} alt="avatar"
                                                                 className="avatar-70 rounded-circle img-fluid"/>
                                                        </div>
                                                        <h4>{creatorUserProfile?.displayName}</h4>
                                                    </div>
                                                    {
                                                        userProfile?.userId !== userId && (
                                                            !userProfile?.followingCreators?.includes(userId) ?
                                                                <Button size="lg"
                                                                        style={{height: "3.5rem", marginTop: "1rem"}}
                                                                        onClick={handleFollow}>
                                                                    + Follow</Button> :
                                                                <Button size="lg"
                                                                        style={{height: "3.5rem", marginTop: "1rem"}}
                                                                        onClick={() => unfollowCreator()}>
                                                                    + Unfollow</Button>
                                                        )
                                                    }
                                                </> :
                                                <CreatorProfilePlaceHolder className="text-center"/>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="row m-0 p-0">
                        <InfiniteScroll
                            dataLength={posts.length}
                            next={() => fetchData(nextPage)}
                            hasMore={nextPage != null}
                            loader={
                                <PostPlaceholder/>
                            }
                            endMessage={
                                <p style={{textAlign: "center"}}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >
                            {
                                posts.map((post, index) => {
                                    return (<Col sm={12} key={index}>
                                        <Post post={post}
                                              showReportPost={showReportPost}
                                              showSignUp={showSignUp}
                                              preferredLanguage={preferredLanguage}
                                              userProfile={userProfile} categories={categories}
                                              showLanguageSelection={showLanguageSelection}/>
                                    </Col>)
                                })
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Creator;