import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Button, Col, Dropdown, Form, Spinner} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {formatDate} from "../../utilities/util";
import EditSpace from "./edit-space";
import axios from "axios";
import {setSpaceNames} from "../../store/app/reducers";
import {useDispatch} from "react-redux";
import {SPACES_URL} from "../../utilities/urls";
import DeleteSpace from "./delete-space";

const spaceNamesURL = SPACES_URL + "/spaces";

const Chat = ({space, messages, sendMessage, userProfile, accessToken}) => {
    const lastMessageRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [showEditSpace, setShowEditSpace] = useState(false);
    const [editSpaceKey, setEditSpaceKey] = useState(10 + Date.now());
    const [showDeleteSpace, setShowDeleteSpace] = useState(false);
    const [deleteSpaceKey, setDeleteSpaceKey] = useState(20 + Date.now());

    useEffect(() => {
        lastMessageRef.current?.scrollIntoView();
    }, [messages]);

    const onSendMessage = (e) => {
        e.preventDefault();
        sendMessage(message, () => setMessage(''));
    }

    const onBackClick = () => {
        navigate('/spaces');
    }

    const handleEditSpaceClose = () => {
        setShowEditSpace(false);
        fetchSpaceNames();
    }

    const fetchSpaceNames = () => {
        axios.get(spaceNamesURL).then((response) => {
            dispatch(setSpaceNames(response.data));
        });
    }

    const showEditModal = () => {
        setShowEditSpace(true);
        setEditSpaceKey(5 + Date.now());
    }

    const handleEditSpace = () => {
        if (userProfile && userProfile.userId === space.createdBy) {
            showEditModal();
        }
    }

    const handleDeleteSpaceClose = () => {
        setShowDeleteSpace(false);
        fetchSpaceNames();
        navigate('/spaces');
    }

    const showDeleteModal = () => {
        setShowDeleteSpace(true);
        setDeleteSpaceKey(5 + Date.now());
    }

    const handleDeleteSpace = () => {
        if (userProfile && userProfile.userId === space.createdBy) {
            showDeleteModal();
        }
    }

    return (
        <Col lg={8} className="d-flex justify-content-between flex-column chat-data p-0 chat-data-right h-100">
            {
                space ?
                    <>
                        <div>
                            <EditSpace key={editSpaceKey} show={showEditSpace} handleClose={handleEditSpaceClose}
                                       accessToken={accessToken} space={space}/>
                            <DeleteSpace key={deleteSpaceKey} show={showDeleteSpace}
                                         handleClose={handleDeleteSpaceClose}
                                         accessToken={accessToken} spaceId={space.id}/>
                            <div className="chat-head">
                                <header
                                    className="pt-3 ps-3 pe-3 pb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="sidebar-toggle" onClick={onBackClick}>
                                            <FontAwesomeIcon icon={faArrowLeft}
                                                             style={{marginTop: "-10px", fontSize: "1.2rem"}}/>
                                        </div>
                                        <h5 className="mb-0 w-100 d-flex justify-content-between">
                                            {space.name}
                                            {
                                                (userProfile && userProfile.userId === space.createdBy) &&
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="bg-transparent" style={{color: 'white'}}>
                                                        <FontAwesomeIcon icon={faEllipsis}
                                                                         style={{fontSize: 1.1 + "rem"}}/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                        <Dropdown.Item className="p-3" to="#"
                                                                       onClick={handleEditSpace}>
                                                            <div className="data ms-2">
                                                                Edit
                                                            </div>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="p-3" to="#"
                                                                       onClick={handleDeleteSpace}>
                                                            <div className="data ms-2">
                                                                Delete
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </h5>
                                    </div>
                                </header>
                            </div>
                            <div className="chat-content scroller">
                                {
                                    messages && messages.length > 0 ?
                                        messages.map((message, index) => (
                                            userProfile?.userId === message.createdBy ?
                                                <div className="chat d-flex other-user" key={index}>
                                                    <div className="chat-user">
                                                        <Link className="avatar m-0" to="">
                                                            <img src={message.creator.avatarUrl} alt="avatar"
                                                                 className="avatar-35 rounded-circle"/>
                                                        </Link>
                                                        <span
                                                            className="chat-time">{formatDate(message.createdAt)}</span>
                                                    </div>
                                                    <div className="chat-detail">
                                                        <div className="chat-message">
                                                            <p>{message.message}</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="chat chat-left" key={index}>
                                                    <div className="chat-user">
                                                        <Link className="avatar m-0" to="">
                                                            <img src={message.creator.avatarUrl} alt="avatar"
                                                                 className="avatar-35 rounded-circle"/>
                                                        </Link>
                                                        <span
                                                            className="chat-time">{formatDate(message.createdAt)}</span>
                                                    </div>
                                                    <div className="chat-detail">
                                                        <div className="chat-message">
                                                            <p>{message.message}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                        )) :
                                        <h5>“A simple hello can lead to incredible discussions!”</h5>
                                }
                                <div ref={lastMessageRef}/>
                            </div>
                        </div>
                        <div className="chat-footer p-3">
                            <Form onSubmit={onSendMessage}>
                                <div className="d-flex flex-column">
                                    <div className="text-end">
                                        {
                                            `${message.length}/150`
                                        }
                                    </div>
                                    <div className="d-flex">
                                        <Form.Control type="text" style={{paddingRight: 3 + "rem"}}
                                                      placeholder="Type your message" value={message}
                                                      onChange={(e) => setMessage(e.target.value)}
                                                      maxLength={150}/>
                                        {
                                            showSpinner ?
                                                <Spinner className="comment-send-spinner"/> :
                                                <Button to="#" type="submit" name="send"
                                                        style={{background: "none", border: "none"}}
                                                        className="paperplane-submit feather-icon">
                                                    <FontAwesomeIcon icon={faPaperPlane}/>
                                                </Button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </> :
                    <h5>
                        Select a space to view messages
                    </h5>
            }
        </Col>
    )
}
export default Chat;    